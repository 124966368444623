<template>
  <div class="delivery-show">
    <is-loading v-if="isLoading" />
    <div v-else  class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/orders">Orders</router-link></li>
                    <li class="breadcrumb-item"><router-link :to="`/orders/${$route.params.orderId}`">View order</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Delivery</li>
                </ol>
            </nav>
        </div>
        <div class="col-12 mb-3">
            <div class="bg-white p-2 d-flex align-items-center">
                <img style="width:50px;height:50px;" class="rounded-circle mr-2" :src="absoluteUrl(delivery.worker.image)"/> 
                <span class="text-capitalize"><span class="h5 mb-0">{{delivery.worker.username}}</span> <br class="d-sm-none">  (designer)</span>
            </div>
        </div>
        <div class="col-12 mb-3">
            <div class="">
                <delivery-files :files="delivery.files" />
            </div>
            <div class="text-muted border-bottom pb-2 my-2 font-weight-bold d-flex justify-content-between">
                <span>{{delivery.created_at | date_ago}}</span>
            </div>
            <div v-if="delivery.is_reviewed">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item mr-1" v-for="star in parseInt(delivery.review.rating)" :key="'0-'+star">
                        <img :src="absoluteUrl('/images/svg/star.svg')" alt="Review rating" width="12"></li>
                    <li class="list-inline-item mr-1" v-for="nostar in  ((delivery.review.rating % 1 == 0 ? 5 : 4) - parseInt(delivery.review.rating))" :key="nostar">
                        <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12"></li>
                </ul>
                <p class="mb-0 text-muted">{{delivery.review.content}}</p>
            </div>
        </div>
        <div class="col-lg-10">
            <delivery-messages />
        </div>
    </div>
  </div>
</template>
<script>

import DeliveryMessages from "@/components/order/DeliveryMessages.vue"
import DeliveryFiles from "@/components/order/DeliveryFiles.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
    components: {
        DeliveryMessages,
        DeliveryFiles,
        IsLoading,
    },
    data(){
        return {
            isLoading: true,
        }
    },
    watch: {
        '$route.params.deliveryId': function (id){
            if(id) this.fetchDelivery()
        },
    },
    computed: {
        delivery(){
            return this.$store.state.orderList.delivery
        },
        order(){
            return this.delivery.order
        },
    },
    methods:{
        fetchDelivery(){
            this.$http.get(`/deliveries/${this.$route.params.deliveryId}/show`)
            .then((response) => {
            this.isLoading = false
                if(response.data.success){
                    this.$store.commit("orderList/SET_DELIVERY", response.data.data)
                }else{
                    this.$router.push({path: `/orders/${this.$route.params.orderId}`})
                }
            })
        }
    },
    mounted(){
        this.fetchDelivery()
    }
}
</script>
