<template>
  <div class="delivery-message row">
    <div class="col-12">
      <div class="card border">
        <div class="py-2">
          <simplebar data-simplebar ref="messageLogPS" style="max-height: 350px;" >
            <ul class="conversation-list mb-0 p-2 p-sm-3 list-unstyled" ref="messageLog">
              <li class="clearfix mb-3" v-for="(message, mIndex) in messages" :key="mIndex">
                <div class="odd" v-if="message.sender.role != 'buyer'">
                  <message-content
                    :buyer="{}"
                    :message="message"
                    :index="mIndex"
                    @showImg="showImg($event)">
                  </message-content>
                </div>
                <div v-else >
                  <message-content
                    :buyer="{}"
                    :message="message"
                    :index="mIndex"
                    @showImg="showImg($event)"
                    >
                  </message-content>
                </div>
              </li>
            </ul>
          </simplebar>
           <vue-easy-lightbox :visible="lightbox.visible" :imgs="lightboxFiles"
            :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import MessageContent from "./MessageContent.vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    MessageContent,
    simplebar,
    VueEasyLightbox
  },
  data() {
    return {
      lightbox:{
        index: null,
        visible:false,
        files:[]
      },
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    delivery(){
      return this.$store.state.orderList.delivery
    },
    messages(){
      return this.delivery.messages
    },
    lightboxFiles(){
      return this.lightbox.files.map(file => {
        if(file.is_video){
          return {
            title: file.name,
            thumb: this.absoluteUrl(file.path),
            mediaType: 'video',
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.name,
            mediaType: 'image',
            src: this.absoluteUrl(file.path) }
        }
      })
    },
  },
  methods: {
    sendAttachment(files){
      if(!files.length){return}
      let formData = new FormData()
      files.forEach((item, index) => formData.append('file_'+index, item))
      this.resquestProcessor(formData, `${this.delivery.id}/attachments`)
    },
    initEditMessage(message){
      this.onEditMode = true
      this.onEdit = {
        id: message.id,
        content: message.content,
      }
      this.modalEditReplyMessage = true
    },
    updateMessage(){
      if(!this.onEdit.content){this.alertError("Please type Something"); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({content: this.onEdit.content}))
      this.resquestProcessor(formData, `${this.onEdit.id}/update`,'UPDATE')
    },
    createMessage(){
      this.$validator.validateAll().then(result => {
        if (!result || !this.newMessage) { this.alertError("Form not valid");return}
        let formData = new FormData()
        formData.append('data', JSON.stringify({content: this.newMessage }))
        this.resquestProcessor(formData, `${this.delivery.id}/create`)
      });
    },
    initReply(messageId){
      this.onEdit.id = messageId
      this.modalEditReplyMessage = true
      this.onEditMode = false
    },
    sendReply(){
      if(!this.onEdit.content){this.alertError("Please type Something"); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onEdit.content}))
      this.resquestProcessor(formData, `${this.onEdit.id}/reply`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/delivery-messages/${action}`,formData)
      .then(response => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.initializeAll()
          this.$store.commit(`orderList/${ftn}_MESSAGE`, response.data.data.message)
          if(ftn == 'ADD'){this.scrollMessageAreaUp();}
        }
     })
    },
    scrollMessageAreaUp(){
      setTimeout(()=>{
        this.$refs.messageLogPS.SimpleBar
          .getScrollElement()
          .scrollTop = this.$refs.messageLog.scrollHeight
      },1000)
    },
    showImg(payload) {
      this.lightbox = {index: payload.index,visible: true,files: [...payload.files]};
    },
    initializeAll(){
      this.modalEditReplyMessage = false
      this.modalUploadFiles = false
      this.onEditMode = false
      this.onEdit = {}
      this.newMessage = ""
      this.lightbox = { index: null,visible:false,files:[]};
      this.$validator.reset()
    },
  },
  mounted(){
    this.scrollMessageAreaUp();
  },

}
</script>

<style lang="scss">
.conversation-list {
  min-height: 550px !important;
}

.conversation-list {
  .conversation-text {
    font-size: 12px;
    .ctext-wrap {
      padding:0.4rem;
      i {
        font-size: 12px;
      }
    }
  }
}

.conversation-list {
  .conversation-text {
    width: auto !important;
    max-width: 90% !important;
    border-radius:6px;
    background:#f7f8f9 !important;
    color:#111b21;
  }
  .odd{
    .conversation-text{
        margin-left:auto;
        background:#d9fdd3 !important;
    }
  }

}
</style>
